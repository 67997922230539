import { graphql } from 'gatsby';
import Link from '@components/Link';

import Img from 'gatsby-image';
import firebase from 'gatsby-plugin-firebase';
import React, { useEffect } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useTranslation } from 'react-i18next';
import { BiCheckShield, BiDonateHeart, BiVideo } from 'react-icons/bi';
import { MdMoneyOff } from 'react-icons/md';
import { useInView } from 'react-intersection-observer';
import Button from '@atoms/Button/Button';
import Divider from '@atoms/Divider/Divider';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import CallToScrollDown from '@components/CallToScrollDown/CallToScrollDown';
import Card from '@components/Card/Card';
import CardGallery from '@components/CardGallery/CardGallery';
import CoachVsTherapy from '@components/CoachVsTherapy/CoachVsTherapy';
import CoveragePricingShortInfo from '@components/CoveragePricingShortInfo';
import FatNumber from '@components/FatNumber/FatNumber';
// Modules
import Footer from '@components/Footer/Footer';
import Header from '@components/Header/Header';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import HowDoesItWorkCTA from '@components/HowDoesItWorkCTA';
import Layout from '@components/layout';
import PrivateAndSecureShortInfo from '@components/PrivateAndSecureShortInfo';
import ProvidersListComponent from '@components/ProvidersList/ProvidersListComponent';
import ProviderStatementsWithTitle from '@components/ProviderStatements';
import QuoteAndUserReview from '@components/QuoteAndUserReview/QuoteAndUserReview';
import Seo from '@components/seo';
import SingleAccordion from '@components/SingleAccordion/SingleAccordion';
import VideoOverlay from '@components/VideoOverlay/VideoOverlay';
import FAQ_THERAPY from '../../../data/faqTherapy';
// DATA
import { useAnalytics, useLocale } from '@hooks';
import TherapyKeySteps from '../../../landingPage/TherapyKeySteps';
import { withLocale, withTracking } from '@hocs';
import { useTracking } from '@hooks';

const VISIBILITY_TRACKER_OPTION = {
    threshold: 0.7,
    trackVisibility: true,
    delay: 2000,
};

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        headerMobileImage: file(
            relativePath: { eq: "you/you-header-mobile.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        headerDesktopImage: file(
            relativePath: { eq: "you/you-header-desktop.png" }
        ) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        appImage: file(relativePath: { eq: "coach/app-visual-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 1288, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        pricingCardImage: file(
            relativePath: { eq: "approach/card-green-background-desktop.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        pricingCardMobileImage: file(
            relativePath: { eq: "approach/card-green-background-mobile.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        coachImage: file(relativePath: { eq: "coach/selected_coaches.png" }) {
            childImageSharp {
                fluid(maxWidth: 910, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

const TherapyPage = ({ location, data: static_images }) => {
    const { t } = useTranslation();
    const { logFirebaseEvent } = useAnalytics();
    const { getAppLinkWithCurrentLang } = useLocale();
    const [introBlockViewRef, introBlockInView] = useInView(
        VISIBILITY_TRACKER_OPTION
    );

    const [testimonyViewRef, testimonyInView] = useInView(
        VISIBILITY_TRACKER_OPTION
    );

    const [providerStatementViewRef, providerStatementInView] = useInView(
        VISIBILITY_TRACKER_OPTION
    );

    const { getContentFromLanguage } = useLocale();
    const { trackPageView } = useTracking();
    
    useEffect(() => {
        trackPageView();
    }, []);

    // FIREBASE EVENTS
    useEffect(() => {
        if (!firebase) {
            return;
        }

        firebase.analytics().logEvent('buchen_funnel_landing', {
            time_log: Date.now(),
            from_location: location.hash,
            from_url_search: location.search,
        });
    }, [firebase]);

    useEffect(() => {
        if (introBlockInView) {
            logFirebaseEvent('therapy_intro_block_impression');
        }

        if (testimonyInView) {
            logFirebaseEvent('therapy_testimony_impression');
        }

        if (providerStatementInView) {
            logFirebaseEvent('therapy_provider_statement_impression');
        }
    }, [
        introBlockInView,
        testimonyInView,
        providerStatementInView,
        logFirebaseEvent,
    ]);

    function renderPrimaryButton() {
        return (
            <div className="Header--ctas-btnPrimaryContainer">
                <a
                    href={getAppLinkWithCurrentLang(
                        process.env.GATSBY_START_THERAPY_FUNNEL_URL
                    )}
                >
                    <Button
                        variant="primary"
                        size="l"
                        label={t('find.pyschologist.cta.label')}
                        iconArrow
                        isMobileFullsize={true}
                    />
                </a>
                <div className="Header--ctas-subtitle">
                    <Text size="s" align="center">
                        {t('find.pyschologist.cta.helper.text')}
                    </Text>
                </div>
            </div>
        );
    }

    function renderSecondaryButton() {
        return (
            <>
                <HowDoesItWorkCTA
                    onButtonClick={() =>
                        logFirebaseEvent(
                            'therapy_how_does_it_work_button_click',
                            {
                                page: 'therapy',
                            }
                        )
                    }
                />
                <VideoOverlay videoSrc="https://www.youtube.com/embed/qAp1eeJx8pI" />
            </>
        );
    }

    const { pricingCardImage, pricingCardMobileImage } = static_images
    return (
        <Layout>
            <Seo
                title={t('therapy.seo.title')}
                description={t('therapy.seo.description')}
            />
            <div className="global_theme-green CoachPage">
                <HeaderNav location={location.pathname} />
                <div ref={introBlockViewRef}>
                    <Section theme="light">
                        <Header
                            theme="light"
                            title={t('therapy.header.title')}
                            list={[
                                t('therapy.header.key.feature.one'),
                                t('therapy.header.key.feature.two'),
                                t('therapy.header.key.feature.three'),
                            ]}
                            toCredit={true}
                            btnPrimary={renderPrimaryButton()}
                            btnSecondary={renderSecondaryButton()}
                            image={
                                <Img
                                    className="Header--visual--img is-big"
                                    fluid={
                                        static_images.headerDesktopImage
                                            .childImageSharp.fluid
                                    }
                                    alt="Header Image"
                                />
                            }
                            imageMobile={
                                <Img
                                    className="Header--visual--img is-small"
                                    fluid={
                                        static_images.headerMobileImage
                                            .childImageSharp.fluid
                                    }
                                    alt="Header Image"
                                />
                            }
                        />
                    </Section>
                </div>

                {/* Quote and User review */}

                {/* 4 Cards */}
                <div id="mehr" ref={testimonyViewRef}>
                    <Section theme="dark" spacingBottom="xs" spacingTop="m">
                        <Section container="short">
                            <Section spacingBottom="xxs">
                                <AnchorLink offset={() => 80} href="#mehr">
                                    <CallToScrollDown
                                        align="center"
                                        theme="white"
                                    />
                                </AnchorLink>
                            </Section>

                            <CardGallery size="2" layout="shifted">
                                {/* BiCheckShield */}
                                <Card
                                    format="v"
                                    variant="heroTitle"
                                    visual={
                                        <FatNumber icon={<BiCheckShield />} />
                                    }
                                    title={t('therapy.key.usp.one.title')}
                                    text={t('therapy.key.usp.one.text')}
                                />

                                <Card
                                    format="v"
                                    variant="heroTitle"
                                    visual={
                                        <FatNumber icon={<BiDonateHeart />} />
                                    }
                                    title={t('therapy.key.usp.two.title')}
                                    text={t('therapy.key.usp.two.text')}
                                    link={
                                        <a
                                            href={getAppLinkWithCurrentLang(
                                                process.env
                                                    .GATSBY_START_THERAPY_FUNNEL_URL
                                            )}
                                        >
                                            <Button
                                                variant="inline"
                                                theme="highlighted"
                                                label={t(
                                                    'find.pyschologist.cta.label'
                                                )}
                                            />
                                        </a>
                                    }
                                />
                                <Card
                                    format="v"
                                    variant="heroTitle"
                                    visual={<FatNumber icon={<MdMoneyOff />} />}
                                    title={t('therapy.key.usp.three.title')}
                                    text={t('therapy.key.usp.three.text')}
                                />
                                <Card
                                    format="v"
                                    variant="heroTitle"
                                    visual={<FatNumber icon={<BiVideo />} />}
                                    title={t('therapy.key.usp.four.title')}
                                    text={t('therapy.key.usp.four.text')}
                                />
                            </CardGallery>
                        </Section>

                        <Section spacingTop="m">
                            <QuoteAndUserReview />
                        </Section>
                    </Section>
                </div>

                {/* Provider Statement Slider */}
                <div ref={providerStatementViewRef}>
                    <Section container="extraLarge">
                        <ProviderStatementsWithTitle
                            ctaLabel={t('find.pyschologist.cta.label')}
                            providerType="therapist"
                        />
                    </Section>
                </div>

                {/* Our providers */}
                <Section theme="light" spacingTop="m" spacingBottom="s">
                    <Section container="large">
                        <Title align="left" size="l">
                            {t('therapy.providers.section.title')}
                        </Title>
                    </Section>
                    <ProvidersListComponent ctaVariant="inline" noHeading />
                </Section>

                {/* How does it work - Title */}
                <Section
                    container="short"
                    spacingTop="s"
                    spacingBottom="xxs"
                    theme="light"
                >
                    <Title align="center">
                        {t('therapy.how.does.it.work.section.title')}
                    </Title>

                    <div className="g_1_4 g_center">
                        <Divider spacing="m" />
                    </div>

                    <div className="g_2_3 g_center">
                        <Text align="center">
                            {t('therapy.how.does.it.work.section.subtitle')}
                        </Text>
                    </div>
                </Section>

                {/* Key Steps */}
                <Section spacingBottom="m" theme="light" container="large">
                    <TherapyKeySteps />
                </Section>

                {/* Costs */}
                <div id="kosten">
                    {/* Pricing Section */}
                    <Section container="large" spacing="m" theme="light">
                        <CoveragePricingShortInfo 
                            imageUrlSharp={pricingCardImage}
                            mobileImageUrlSharp={pricingCardMobileImage}
                        />

                        {/* <Divider spacing="l" invisible /> */}

                        <Link to="/pricing/">
                            <Button
                                theme="highlighted"
                                label={t('pricing.card.button.to.coverage')}
                                iconArrow
                                align="center"
                            />
                        </Link>
                    </Section>
                </div>

                {/* Privacy */}
                <Section
                    theme="soft"
                    spacingTop="m"
                    spacingBottom="m"
                    container="large"
                >
                    <PrivateAndSecureShortInfo />
                </Section>

                {/* FAQ */}
                <Section container="short" spacingBottom="m" spacingTop="m">
                    <Title tag="h3" size="xl" align="center" theme="dark">
                        {t('faq.section.title.short')}
                    </Title>
                    <Divider invisible spacing="xl" />
                    <SingleAccordion
                        data={getContentFromLanguage(FAQ_THERAPY)}
                        comingFrom="therapy"
                    />
                </Section>

                {/* Coaching oder Therapie? */}
                <Section
                    container="large"
                    spacingTop="s"
                    spacingBottom="l"
                    theme="light"
                >
                    <Title align="center">
                        {t('coaching.vs.therapy.section.title')}
                    </Title>
                    <Divider spacing="m" invisible />

                    <CoachVsTherapy />
                </Section>

                {/* Footer */}
                <Footer variant="extended" />
            </div>
        </Layout>
    );
};

export default withTracking(withLocale(TherapyPage));
